export const webSocketEndpoint =
  process.env.NODE_ENV === "production"
    ? "wss://akusen.to/ws2/"
    : "ws://localhost:1337";

export const apiPrefix =
  process.env.NODE_ENV === "production"
    ? "https://akusen.to/api"
    : "http://localhost:7000/api";

export const frontEndPrefix =
  process.env.NODE_ENV === "production"
    ? "https://akusen.to"
    : "http://localhost:3000";
