import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from "@tiptap/core";

export interface HighlightOptions {
  multicolor: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    unvoiced: {
      /**
       * Set a highlight mark
       */
      setUnvoiced: (attributes?: { color: string }) => ReturnType;
      /**
       * Toggle a highlight mark
       */
      toggleUnvoiced: (attributes?: { color: string }) => ReturnType;
      /**
       * Unset a highlight mark
       */
      unsetUnvoiced: () => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

const unvoicedClass = "unvoiced";

export const Unvoiced = Mark.create<HighlightOptions>({
  name: "unvoiced",

  addOptions() {
    return {
      multicolor: false,
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    // if (!this.options.multicolor) {
    //     return {}
    // }

    return {
      unvoiced: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute("data-color") || element.style.backgroundColor,
        renderHTML: (attributes) => {
          return {
            style:
              "text-decoration-line: underline; text-decoration-style: double; text-underline-offset: 1px;",
            // class: unvoicedClass
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element: any) => {
          const style = element.style;
          return (
            style.textDecorationLine === "underline" &&
            style.textDecorationStyle === "double" &&
            style.textUnderlineOffset === "1px" &&
            null
          );
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setUnvoiced:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleUnvoiced:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      unsetUnvoiced:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-j": () => this.editor.commands.toggleUnvoiced(),
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },
});
