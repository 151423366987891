import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { HighAccent } from "./extensions/high-accent";
import { Unvoiced } from "./extensions/unvoiced";
import * as Y from "yjs";

import Collaboration from "@tiptap/extension-collaboration";
import { IndexeddbPersistence } from "y-indexeddb";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { CollaborationCursor } from "@tiptap/extension-collaboration-cursor";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { Table } from "@tiptap/extension-table";
import { TableRow } from "@tiptap/extension-table-row";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableCell } from "@tiptap/extension-table-cell";
import { Triangle, triangleCSS } from "./extensions/triangle-intonation";
import { webSocketEndpoint } from "./constants";
import { ArrowUp, arrowUpCSS } from "./extensions/arrow-up";
import Placeholder from "@tiptap/extension-placeholder";

const StyledEditorContent = styled(EditorContent)`
  padding-top: 10px;
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror {
    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 3px 5px;
        position: relative;
        vertical-align: top;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }

      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }

      p {
        margin: 0;
      }
    }
  }

  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -2.4em;
    user-select: none;
    white-space: nowrap;
    :before {
      content: "";
      border: 1px solid #1e90ff;
      position: absolute;
      bottom: -12px;
      left: 0px;
      height: 10px;
    }
  }

  .highAccent {
    .triangle {
      &::after {
        ${triangleCSS};
        bottom: 17px;
      }
    }
  }

  .triangle {
    &::after {
      ${triangleCSS};
      bottom: 15px;
    }
  }

  .highAccent {
    .arrowUp {
      &::after {
        ${arrowUpCSS};
        bottom: 19px;
      }
    }
  }

  .arrowUp {
    &::after {
      ${arrowUpCSS};
      bottom: 15px;
    }
  }

  .paragraphClass {
    line-height: 2.5;
    word-break: keep-all;
  }
`;

export const ToolbarContainer = styled.div`
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 1;
  padding-bottom: 10px;
`;

//      bottom: ${(props) => props.isAboveAccentLine ? "17px" : "15px"};
const Tiptap = () => {
  let { id } = useParams<{ id: string }>();
  const provider = useMemo(() => {
    return new HocuspocusProvider({
      url: webSocketEndpoint,
      name: id!,
    });
  }, [id]);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: false,
        paragraph: {
          HTMLAttributes: {
            class: "paragraphClass",
          },
        },
      }),
      Placeholder.configure({
        // Use a placeholder:
        placeholder: "Write something …",
        // Use different placeholders depending on the node type:
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'What’s the title?'
        //   }

        //   return 'Can you add some further context?'
        // },
      }),
      Collaboration.configure({
        document: provider.document,
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          name: "Other user",
          color: "#1e90ff",
        },
      }),
      HighAccent,
      Unvoiced,
      Triangle,
      ArrowUp,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    content: "",
  });

  return (
    <div>
      {editor && (
        <ToolbarContainer>
          <button
            onClick={() =>
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run()
            }
          >
            insertTable
          </button>
          <button
            onClick={() => editor.chain().focus().addColumnBefore().run()}
          >
            addColumnBefore
          </button>
          <button onClick={() => editor.chain().focus().addColumnAfter().run()}>
            addColumnAfter
          </button>
          <button onClick={() => editor.chain().focus().deleteColumn().run()}>
            deleteColumn
          </button>
          <button onClick={() => editor.chain().focus().addRowBefore().run()}>
            addRowBefore
          </button>
          <button onClick={() => editor.chain().focus().addRowAfter().run()}>
            addRowAfter
          </button>
          <button onClick={() => editor.chain().focus().deleteRow().run()}>
            deleteRow
          </button>
          <button onClick={() => editor.chain().focus().deleteTable().run()}>
            deleteTable
          </button>
          <button onClick={() => editor.chain().focus().mergeCells().run()}>
            mergeCells
          </button>
          <button onClick={() => editor.chain().focus().splitCell().run()}>
            splitCell
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          >
            toggleHeaderColumn
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          >
            toggleHeaderRow
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          >
            toggleHeaderCell
          </button>
          <button onClick={() => editor.chain().focus().mergeOrSplit().run()}>
            mergeOrSplit
          </button>
          <button
            onClick={() =>
              editor.chain().focus().setCellAttribute("colspan", 2).run()
            }
          >
            setCellAttribute
          </button>
          <button onClick={() => editor.chain().focus().fixTables().run()}>
            fixTables
          </button>
          <button onClick={() => editor.chain().focus().goToNextCell().run()}>
            goToNextCell
          </button>
          <button
            onClick={() => editor.chain().focus().goToPreviousCell().run()}
          >
            goToPreviousCell
          </button>
        </ToolbarContainer>
      )}
      {editor && (
        <BubbleMenu editor={editor}>
          <button
            onClick={() => editor.chain().focus().toggleHighAccent().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            high accent
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnvoiced().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            unvoiced
          </button>
          <button
            onClick={() => editor.chain().focus().toggleTriangle().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            triangle
          </button>
          <button
            onClick={() => editor.chain().focus().toggleArrowUp().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            arrow up
          </button>
        </BubbleMenu>
      )}
      <StyledEditorContent editor={editor} />
    </div>
  );
};

export default Tiptap;
