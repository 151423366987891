import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { apiPrefix, frontEndPrefix } from "./constants";

export const Documents = (props: any) => {
  const [documents, setDocuments] = useState<any[]>();
  const navigate = useNavigate();

  const makeNewDocument = async () => {
    const nameOfDocument = window.prompt("Name", "Untitled Document");

    const result = await axios.post(`${apiPrefix}/documents`, {
      name: nameOfDocument,
      type: "TIPTAP",
    });

    navigate(`../editor/${result.data.id}`);
  };

  useEffect(() => {
    // Create an scoped async function in the hook
    async function getDocuments() {
      const result = await axios.get(`${apiPrefix}/documents`);
      setDocuments(result.data);
      console.log(result.data);
    }
    // Execute the created function directly
    getDocuments();
  }, []);

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Akusento
          </Typography>
          <Button color="inherit" onClick={makeNewDocument}>
            New Document
          </Button>
        </Toolbar>
      </AppBar>
      <br />
      <Stack spacing={2}>
        {documents
          ?.filter((document) => document.type === "TIPTAP")
          .map((document) => {
            const updatedAt = new Date(document.updatedAt);
            return (
              <Card>
                <CardContent>
                  <Typography>{document.name}</Typography>
                  {document.updatedAt && (
                    <Typography>
                      Last updated: {updatedAt.toLocaleString()}
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    href={`${frontEndPrefix}/editor/${document.id}`}
                  >
                    Edit
                  </Button>
                </CardActions>
              </Card>
            );
          })}
      </Stack>
    </Container>
  );
};
