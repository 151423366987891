import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from "@tiptap/core";
import { css } from "styled-components";

export interface HighlightOptions {
  multicolor: boolean;
  HTMLAttributes: Record<string, any>;
}

const triangleClassName = "triangle";
export const triangleCSS = css`
  content: "△";
  position: relative;
  //bottom: 17px;
  left: -13.5px;
  display: inline-block;
  width: 0;
  white-space: nowrap;
`;
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    triangle: {
      /**
       * Set a highlight mark
       */
      setTriangle: (attributes?: { color: string }) => ReturnType;
      /**
       * Toggle a highlight mark
       */
      toggleTriangle: (attributes?: { color: string }) => ReturnType;
      /**
       * Unset a highlight mark
       */
      unsetTriangle: () => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const Triangle = Mark.create<HighlightOptions>({
  name: "triangle",

  addAttributes() {
    // if (!this.options.multicolor) {
    //     return {}
    // }

    return {
      triangle: {
        default: null,
        renderHTML: (attributes) => {
          return {
            class: triangleClassName,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element: any) => {
          return element.class === triangleClassName && null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setTriangle:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      toggleTriangle:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      unsetTriangle:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-k": () => this.editor.commands.toggleTriangle(),
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },
});
